import './style.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS.js';
import OSM from 'ol/source/OSM';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS.js';
import proj4 from 'proj4';
import WMSCapabilities from 'ol/format/WMSCapabilities.js';

const osm = new TileLayer({
  source: new OSM()
});

/*
var layerExtent;
var carandaImovelCenter ;
var parser = new WMSCapabilities();
fetch('https://geo01.jsk.eng.br/geoserver/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities')
  .then(function(response) {
    return response.text();
  })
  .then(function(text) {
    var result = parser.read(text);

    // Encontrar a camada desejada
    var layerExtent = result.Capability.Layer.Layer.find(l => l.Name === 'EST_CARANDA:EST_CARANDA_IMOVEL').EX_GeographicBoundingBox;

    if (layerExtent) {
      extendCoor(layerExtent);
    } else {
      console.error('Camada não encontrada.');
    }
  })

function extendCoor(originalCoordinates){
// Definir as projeções de origem (EPSG:4326) e destino (EPSG:31981)
proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
proj4.defs('EPSG:31981', '+proj=utm +zone=21 +south +datum=WGS84 +units=m +no_defs');

// Calcular o centro das coordenadas originais
const centerX = (originalCoordinates[0] + originalCoordinates[2]) / 2;
const centerY = (originalCoordinates[1] + originalCoordinates[3]) / 2;

// Reprojetar o centro para o sistema de coordenadas de destino
carandaImovelCenter = proj4('EPSG:4326', 'EPSG:31981', [centerX, centerY]);
createLayres(carandaImovelCenter)

}

function createLayres(carandaImovelCenter){
  const VIZINHOS_IMOVEL = new ImageWMS({
    url: 'https://geo01.jsk.eng.br/geoserver/wms',
    params: {
      'LAYERS': 'CAMPO_GRANDE:CGR_IMOVEIS',
      'FORMAT': 'image/png',
      
    },
    serverType: 'geoserver',
    crossOrigin: 'anonymous',
  });
  
  // Adicione uma restrição de raio para os imóveis vizinhos
  VIZINHOS_IMOVEL.updateParams({
    'CQL_FILTER': 'DWITHIN(geom, POINT(' + carandaImovelCenter[0] + ' ' + carandaImovelCenter[1] + '), 1000, meters)',
  });
    
  const VIZINHOS_IMOVEL_LAYER = new ImageLayer({
    source: VIZINHOS_IMOVEL,
    opacity: 1,
    visible: true,
  });

  map.addLayer(VIZINHOS_IMOVEL_LAYER)
}
*/

const wmsts =new TileLayer({
  extent: [ -6075928.29722454,-2326581.923082079,-6075416.425541352,-2326276.947634159],
  source: new TileWMS({
    url: 'https://geo01.jsk.eng.br/geoserver/wms',
    params: {'LAYERS': 'EST_CARANDA:EST_CARANDA_RASTER', 'FORMAT': 'image/png8', 'TILED': true},
    serverType: 'geoserver',
    // Countries have transparency, so do not fade tiles:
    transition: 0,
  }),
});

const CARANDA_IMOVEL = new TileWMS({
  url: 'https://geo01.jsk.eng.br/geoserver/wms?',
  params: {
      'LAYERS': 'CAMPO_GRANDE:EST_CARANDA_IMOVEL',
      'TILED': true,
      'FORMAT': 'image/png8',
      
  },
  serverType: 'geoserver', // Dependendo do servidor WMS que você está usando
});

// Configure a camada WMS com a propriedade maxResolution
 const CARANDA_IMOVEL_LAYER = new TileLayer({
  source: CARANDA_IMOVEL,
  opacity: 1,
  visible: true,
});



const VIZINHOS_IMOVEL = new ImageWMS({
  url: 'https://geo01.jsk.eng.br/geoserver/wms',
  params: {
    'LAYERS': 'CAMPO_GRANDE:CGR_IMOVEIS',
    'FORMAT': 'image/png',
    'STYLES': 'CAMPO_GRANDE:EST_IMOVEIS_VIZINHOS'
    
  },
  serverType: 'geoserver',
  crossOrigin: 'anonymous',
});


proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
proj4.defs('EPSG:31981', '+proj=utm +zone=21 +south +datum=WGS84 +units=m +no_defs');

const coordinateCenter = [-54.577696, -20.449663];
const coordinateCenterSirgas = proj4('EPSG:4326', 'EPSG:31981', [coordinateCenter[0], coordinateCenter[1]]);

console.log(coordinateCenterSirgas)
// Adicione uma restrição de raio para os imóveis vizinhos
VIZINHOS_IMOVEL.updateParams({
  'CQL_FILTER': 'DWITHIN(geom, POINT(' + coordinateCenterSirgas[0] + ' ' + coordinateCenterSirgas[1] + '), 500, meters)',
});
  
const VIZINHOS_IMOVEL_LAYER = new ImageLayer({
  source: VIZINHOS_IMOVEL,
  opacity: 1,
  visible: true,
});


var CARANDA_PANO = new ImageWMS({
  url: 'https://geo01.jsk.eng.br/geoserver/wms',
  params: {
      'LAYERS': 'EST_CARANDA:EST_CARANDA_PANO',
      //'TILED': true,
      'FORMAT': 'image/png'
  },
});
var CARANDA_PANO_LAYER = new ImageLayer({
  source: CARANDA_PANO,
  opacity: 1,
  visible: true,
});


const view = new View({
  center: [-6075626.954010579, -2326405.2970466036],
    zoom: 18,
    minZoom:16,
    maxZoom: 25,
    extent: [-6078243.8254,-2328548.9658,-6070953.6438,-2323714.3238],
})

const map = new Map({
  target: 'map',
  layers: [ osm, wmsts,VIZINHOS_IMOVEL_LAYER, CARANDA_IMOVEL_LAYER, CARANDA_PANO_LAYER ],
  view: view,
  
});


map.on('singleclick', function (evt) {
  
  var viewResolution = map.getView().getResolution();
  const url = CARANDA_PANO.getFeatureInfoUrl(
    evt.coordinate,
    viewResolution,
    'EPSG:3857',
    {'INFO_FORMAT': 'application/json'}
  );
  fetch(url)
  .then((response) => response.json())
  .then((resp) => {
    let featureInfo = resp.features[0];

    if (featureInfo) {
      let pano = featureInfo.properties.PANO;
      window.open("https://caranda.jsk.eng.br/web360/"+pano , '_blank')
    } else {
      console.log('Nenhuma feature encontrada.');
    }
  });
});


